<template>
  <div class="hold-transition">
    <div
      class="modal fade"
      id="modal-form-factura"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Ingresar Factura
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <label for="">Ingreso numero de Factura</label>

              <input
                type="text"
                class="form-control form-control-sm"
                id="factura"
                v-model="form.factura"
                :class="$v.form.factura.$invalid ? 'is-invalid' : 'is-valid'"
              />
            </div>
            <div class="modal-footer" v-show="form.factura">
              <button type="button" class="btn btn-primary" @click="save()">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import pagination from "laravel-vue-pagination";
import { required } from "vuelidate/lib/validators";

export default {
  name: "FaltantesFactura",
  components: {
  },
  data() {
    return {
      form: {},
      datos: [],
    };
  },
  validations: {
    form: {
      factura: {
        required,
      },
    },
  },

  methods: {
    llenar_modal(faltante) {
      this.diferencia_guia_notificacione_cobro_id = faltante.id;

      axios
        .get("/api/hidrocarburos/obtener_documentos/" + faltante.id)
        .then((response) => {
          if (Array.isArray(response.data)) {
            this.documentos = response.data.map((documento) => {
              return {
                ...documento,
                estado:
                  documento.estado === 1
                    ? "Gestionado"
                    : documento.estado === 2
                    ? "Verificado"
                    : "Pendiente",
                file_path: documento.link || "",
              };
            });
            this.datos = faltante;
            this.estadoNotificacion = faltante.estado;
          }
          /*           this.$parent.cargando = false;|
           */
        })
        .catch((error) => {
          console.error(error);
          /*           this.$parent.cargando = false;
           */
        });
    },
    save() {
      let data = {
        factura: this.form.factura,
        id: this.datos.id,
      };

      axios({
        method: "POST",
        url: "/api/hidrocarburos/faltantes/ingreso_factura",
        data: data,
      })
        .then((response) => {
          this.$swal({
            title: "Se ha enviado la respuesta",
            text: "La justificación ha sido enviada correctamente.",
            icon: "success",
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.getIndex();
        })
        .catch((error) => {
          console.error(error);
          this.$swal({
            title: "Error",
            text: "Hubo un error al enviar la respuesta.",
            icon: "error",
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },
};
</script>
